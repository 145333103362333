<div class="sidebar-nav">
  <div class="sidebar-items-group">
    @if (sidebarItems) {
      <ul class="sidebar-items">
        @for (item of sidebarItems; track item.title) {
          @if (!item.hidden || !item.hidden()) {
            <li class="sidebar-item {{ item.active ? 'active' : '' }}"
              (click)="navigateToMenuItem(item)">
              <a class="sidebar-item-link" routerLink="{{ item.link }}">
                <svg class="regular-img-size pointer">
                  <use [attr.xlink:href]="'../../../../assets/icons/' + item.svg + '.svg#s'"></use>
                </svg>
                <span>{{ item.title }}</span>
                @if (item.notificationCount) {
                  <span class="notif-count">{{item.notificationCount}}</span>
                }
              </a>
            </li>
          }
        }
      </ul>
    }
  </div>
</div>
