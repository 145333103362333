import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {isObeliskZoneCircle, Obelisk} from '../interfaces/exploration.interfaces';
import {GeoBBox, QGraph} from '../interfaces/geo-and-movement.interfaces';
import {Dict} from '../interfaces/common.interfaces';
import {EnvProviderService} from './env-provider.service';
import {ObeliskImportance} from '../interfaces/obelisks.enums';

@Injectable({
  providedIn: 'root'
})
export class ExplorationService {

  readonly ROOT_URL = '/exploration';

  constructor(private http: HttpClient, private env: EnvProviderService) { }

  public loadObelisks$(importance: ObeliskImportance[], bbox?: GeoBBox, search = '', limit?: number): Observable<Obelisk[]> {
    let params = new HttpParams().set('importance', importance.join(','));

    if (bbox) {
      params = params.append('bbox', `${bbox.sw.lng},${bbox.sw.lat},${bbox.ne.lng},${bbox.ne.lat}`);
    }

    if (search) {
      params = params.set('search', search);
    }

    if (limit) {
      params = params.set('limit', limit.toString());
    }

    return this.http.get<Obelisk[]>(`${this.env.environment.API_URL}${this.ROOT_URL}/`, {params}).pipe(
      map((rawObelisks) => rawObelisks.map((rawObelisk) => this.transformObeliskFromServer(rawObelisk)))
    );
  }

  createObelisk$(obelisk: Obelisk): Observable<unknown> {
    return this.http.post(`${this.env.environment.API_URL}${this.ROOT_URL}/`, this.transformObeliskForServer(obelisk));
  }

  updateObelisk$(obelisk: Obelisk): Observable<unknown> {
    return this.http.patch(`${this.env.environment.API_URL}${this.ROOT_URL}/${obelisk.uuid}`, this.transformObeliskForServer(obelisk));
  }

  getObeliskById$(id: number): Observable<Obelisk> {
    return this.http.get<Obelisk>(`${this.env.environment.API_URL}${this.ROOT_URL}/${id}`)
      .pipe(
        map((raw) => this.transformObeliskFromServer(raw))
      );
  }

  private transformObeliskFromServer(raw: any): Obelisk {
    const obelisk: Obelisk = {
      uuid: raw.uuid,
      name: raw.name,
      subTitle: raw.sub_title,
      description: raw.description,
      location: {
        lng: raw.lng,
        lat: raw.lat,
      },
      svg: raw.svg,
      type: raw.type,
      importance: raw.importance,
      zone: raw.zone,
      config: raw.config,
      createdAt: new Date(raw.created_at),
      updatedAt: new Date(raw.updated_at),
    };

    if (!isObeliskZoneCircle(obelisk.zone)) {
      const absolutePolygon: QGraph = {
        vertices: {},
        connections: []
      };

      obelisk.zone.vertices.forEach((vertex, index) => {
        const vertexId = `v${index}`;
        absolutePolygon.vertices[vertexId] = {
          id: vertexId,
          coords: {
            lng: obelisk.location.lng - vertex.lngDiff,
            lat: obelisk.location.lat - vertex.latDiff,
          }
        };

        if (index > 0) {
          absolutePolygon.connections.push({
            startVertexId: `v${index - 1}`,
            endVertexId: vertexId,
          });
        }
      });

      absolutePolygon.connections.push({
        startVertexId: `v${obelisk.zone.vertices.length - 1}`,
        endVertexId: 'v0',
      });

      obelisk.zone.absolutePolygon = absolutePolygon;
    }

    return obelisk;
  }

  private transformObeliskForServer(obelisk: Obelisk): Dict<any> {
    return {
      name: obelisk.name,
      sub_title: obelisk.subTitle,
      description: obelisk.description,
      lng: obelisk.location.lng,
      lat: obelisk.location.lat,
      svg: obelisk.svg,
      type: obelisk.type,
      importance: obelisk.importance,
      zone: obelisk.zone,
      config: obelisk.config
    }
  }
}
