<mat-toolbar class="toolbar top">
  <span class="main-heading-text">{{isNew ? 'Create New' : 'Edit'}} Obelisk</span>
  <img class="pointer" src="assets/project-icons/x.svg" alt="X" routerLink="/exploration" />
</mat-toolbar>

<div class="map-editor-container">
  @if (obeliskForm) {
    <form class="controls" [formGroup]="obeliskForm">
      <div class="controls-row">
        <button
          class="secondary-bar-btn add-buttons"
          (click)="editingObeliskCenter = !editingObeliskCenter; editingObeliskZone = false"
          [ngClass]="{ active: editingObeliskCenter }"
          [disabled]="!!obeliskForm.get('location').value"
          >
          <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
            <use xlink:href="assets/project-icons/add.svg#a"></use>
          </svg>
          Add Obelisk Center
        </button>
      </div>
      <div class="chips-container">
        @if (!!obeliskForm.get('location').value) {
          <app-active-chip
            [text]="'Obelisk Center'"
            [selected]="editingObeliskCenter"
            [color]="'default'"
            (click)="clickObeliskBtn()"
            (close)="removeObeliskMarker(); editingObeliskCenter = false"
            >
          </app-active-chip>
        }
      </div>
      @if (obeliskForm.get('location').value) {
        <div class="controls-row" style="justify-content: center">
          <app-chooser [options]="zoneTypeOptions" (optionChosen)="choseZoneType($event)">
          </app-chooser>
        </div>
        @if (obeliskForm.get('zoneType').value === 'circle') {
          <div class="controls-row margin-top-18">
            <input type="range" min="4" max="100" formControlName="zoneRadius" class="general-range" />
            <span class="range-label">
              {{ metersToPretty(obeliskForm.get('zoneRadius').value) }}
            </span>
          </div>
        }
      }
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-top-18 margin-bottom-18" color="accent">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" minlength="1" maxlength="100"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-bottom-18" color="accent">
          <mat-label>Sub Title</mat-label>
          <input matInput formControlName="subTitle" maxlength="1000"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field mat-text-area margin-bottom-24" color="accent">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description" rows="4" maxlength="700"></textarea>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-bottom-18" color="accent">
          <mat-label>SVG</mat-label>
          <input matInput formControlName="svg" minlength="1"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <app-dropdown
          class="margin-bottom-18"
          title="Importance"
          [options]="importanceOptions"
          formControlName="importance"
        ></app-dropdown>
      </div>
    </form>
  }

  <div id="map-container" class="map">
    <!--    <div class="map-controls" *ngIf="(mapControlVisibility$ | async)?.state === 'show'">-->
    <!--      <div class="map-control-slot pointer" *ngIf="(mapControlVisibility$ | async)?.showConfirm" (click)="mapControlPanelActions$.next({ button: 'confirm' })">-->
    <!--        <svg class="regular-img-size color-green">-->
    <!--          <use xlink:href="assets/project-icons/check.svg#c"></use>-->
  <!--        </svg>-->
<!--      </div>-->
<!--    </div>-->

<button class="map-btn" style="top: 156px" (click)="goToMyLocation()">
  <svg class="pointer" viewBox="0 0 24 24" width="20" height="20">
    <use xlink:href="assets/map-icons/location.svg#c"></use>
  </svg>
</button>

<button class="map-btn" style="top: 200px" (click)="toggleMagStyle()">
  <svg class="pointer" viewBox="0 0 24 24" width="20" height="20">
    <use xlink:href="assets/map-icons/layers.svg#c"></use>
  </svg>
</button>
</div>
</div>

<mat-toolbar class="toolbar bottom element-shadow">
  <span class="filler"></span>
  @if (isNew) {
    <button class="primary-bar-btn" (click)="createObelisk()" [disabled]="!isValid() || saving">
      <svg class="regular-img-size">
        <use xlink:href="assets/project-icons/check.svg#c"></use>
      </svg>
      Create
    </button>
  } @else {
    <button class="primary-bar-btn" (click)="updateObelisk()" [disabled]="!isValid() || saving">
      <svg class="regular-img-size">
        <use xlink:href="assets/project-icons/check.svg#c"></use>
      </svg>
      Update
    </button>
  }
</mat-toolbar>
