import {GeoCoords, QGraph} from './geo-and-movement.interfaces';
import {Dict} from './common.interfaces';
import {ObeliskImportance, ObeliskType} from './obelisks.enums';

export interface Obelisk {
  uuid?: string;
  name: string;
  subTitle?: string;
  description?: string;
  location?: GeoCoords;
  svg?: string;
  type: ObeliskType;
  importance?: ObeliskImportance | string;
  zone?: ObeliskZone;
  config?: Dict<any>;
  createdAt?: Date;
  updatedAt?: Date;
}


export interface ObeliskZoneCircle {
  type: 'circle';
  radius: number; // in meters
}

export interface RelativeCoords {
  lngDiff: number;
  latDiff: number;
}

export interface ObeliskZonePolygon {
  type: 'polygon';
  vertices: RelativeCoords[];
  absolutePolygon?: QGraph;
}

export type ObeliskZone = ObeliskZoneCircle | ObeliskZonePolygon;

export const isObeliskZoneCircle = (zone: ObeliskZone): zone is ObeliskZoneCircle => zone.type === 'circle';
